import React from "react"

export default function hero() {
  return (
    <section class="relative home-wrapper items-center overflow-hidden">
      <div class="section wf-section">
        <div class="section-container center w-container">
          <div class="section-heading-container">
            <h2 class="section-heading">It's never been easier to incorporate in Turkey.</h2>
            <p class="section-p center">
              We offer much more than just a name on a piece of paper you're
              used to seeing. That's because our team understands the complex
              requirements to let you start your
              business as smoothly as you want.
            </p>
          </div>
        </div>
        <div class="section-container-agent ad-hoc w-container">
          <div class="w-layout-grid new-grid">
            <div
              id="w-node-_2fa9c89a-8137-7d97-ce20-25bf41dd81b8-8e8b22b5"
              class="panel"
            >
              <div class="panel-txt-container justify-center">
                <div>
                  <h3 class="panel-heading">Choose a company type</h3>
                  <p class="text">
                  The first step is to decide on the legal entity type. There are benefits to each choice, and we've laid out some information below to help you decide. The most common option is an LDT STI (Limited Liability Company).
                  </p>
                </div>
              </div>
              <div class="panel-img-container">
                <img
                  src="https://res.cloudinary.com/avomeraydin/image/upload/v1665487027/assets/images/Screen_Shot_2022-10-11_at_14.16.51_grkqrr.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 85vw, (max-width: 767px) 81vw, (max-width: 991px) 85vw, (max-width: 1279px) 44vw, 467px"
                  srcset="https://res.cloudinary.com/avomeraydin/image/upload/v1665487027/assets/images/Screen_Shot_2022-10-11_at_14.16.51_grkqrr.png 500w, https://res.cloudinary.com/avomeraydin/image/upload/v1665487027/assets/images/Screen_Shot_2022-10-11_at_14.16.51_grkqrr.png 800w, https://res.cloudinary.com/avomeraydin/image/upload/v1665487027/assets/images/Screen_Shot_2022-10-11_at_14.16.51_grkqrr.png 1040w"
                  alt=""
                  class="panel-img"
                />
              </div>
            </div>
            <div
              id="w-node-_2fa9c89a-8137-7d97-ce20-25bf41dd81c0-8e8b22b5"
              class="panel direction-vertical"
            >
              <div class="panel-txt-container">
                <h3 class="panel-heading">Limited Liability Company (Limited Sirket - LTD. STI.)</h3>
                <p class="text">
                A limited liability company is an organizational business structure in Turkey that helps business owners separate their personal liabilities from business liabilities.
                </p>
              </div>
              <div class="panel-img-container">
                <img
                  src="https://res.cloudinary.com/avomeraydin/image/upload/v1676500893/Screen_Shot_2023-02-16_at_01.41.26_tavesg.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 85vw, (max-width: 767px) 81vw, (max-width: 991px) 85vw, (max-width: 1279px) 94vw, 998px"
                  srcset="https://res.cloudinary.com/avomeraydin/image/upload/v1676500893/Screen_Shot_2023-02-16_at_01.41.26_tavesg.png 500w, https://res.cloudinary.com/avomeraydin/image/upload/v1676500893/Screen_Shot_2023-02-16_at_01.41.26_tavesg.png 800w, https://res.cloudinary.com/avomeraydin/image/upload/v1676500893/Screen_Shot_2023-02-16_at_01.41.26_tavesg.png 1040w"
                  alt=""
                  class="panel-img"
                />
              </div>
            </div>
            <div
              id="w-node-_2fa9c89a-8137-7d97-ce20-25bf41dd81c8-8e8b22b5"
              class="panel direction-vertical"
            >
              <div class="panel-txt-container">
                <h3 class="panel-heading">Joint Stock Company (Anonim Sirket - A.S.)</h3>
                <p class="text">
                An A.S. is an independent entity for tax purposes. Corporations generally pay corporate taxes on their own profits, and their shareholders pay personal income on the capital distributed to them.
                </p>
                <div class="div-block-454">
                    <div>Focus on your business. We'll handle the details</div>
                </div>
              </div>
              <div class="panel-img-container">
                <img
                  src="https://res.cloudinary.com/avomeraydin/image/upload/v1676500974/Screen_Shot_2023-02-16_at_01.42.47_y5kzdb.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 85vw, (max-width: 767px) 81vw, (max-width: 991px) 85vw, (max-width: 1279px) 94vw, 998px"
                  srcset="https://res.cloudinary.com/avomeraydin/image/upload/v1676500974/Screen_Shot_2023-02-16_at_01.42.47_y5kzdb.png 500w, https://res.cloudinary.com/avomeraydin/image/upload/v1676500974/Screen_Shot_2023-02-16_at_01.42.47_y5kzdb.png 1080w, https://res.cloudinary.com/avomeraydin/image/upload/v1676500974/Screen_Shot_2023-02-16_at_01.42.47_y5kzdb.png 2080w"
                  alt="Screenshot of the Agent dashboard."
                  class="panel-img"
                />
              </div>
            </div>
          </div>
        </div>
        <img
          src="https://assets.website-files.com/609486d2fafa37698a57db5b/6298d8ee7eb883253b15e422_agent-herobg.svg"
          loading="lazy"
          alt=""
          class="hero-bg saturation _125"
        />
      </div>
    </section>
  )
}
