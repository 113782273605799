import * as React from "react"
import Hero from '../components/start/starthero'
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import CTA from "../components/cta"


import Seo from "../components/seo"

export default function index() {
  return (
  <>
      <Seo title="Launch Turkey" metaDescription="Launch Turkey is help people to launch their own entity in Turkey easily." />
      <body>
        <Navbar />
        <Hero />
        <CTA />
        <Footer />
      </body>
  </>
  )
}
